.outside-background {
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.main-background {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
}

/* (A) PIE CHART */
.donut {
    /* (A1) CIRCLE */
    width: 240px;
    height: 240px;
    border-radius: 50%;

    /* (A2) SEGMENTS */
    /* background: conic-gradient(
    red 0deg 120deg,
    green 120deg 240deg,
    blue 240deg 360deg
  ); */
}

/* (B) DONUT "CONVERSION" */
/* (B1) SMALLER CIRCLE */
.hole {
    width: 210px;
    height: 210px;
    border-radius: 50%;
    background: #fff;
}

/* (B2) "CONVERT PIE TO DONUT" - CENTER SMALLER CIRCLE */
.donut {
    display: flex;
    align-items: center;
    justify-content: center;
}

.element-possition {
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
}
