@font-face {
    font-family: 'Muli';
    src: url('./assets/fonts/Muli.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Comfortaa';
    src: url('./assets/fonts/Comfortaa.ttf') format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url('./assets/fonts/OpenSans.ttf') format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'MyriadWebPro-Bold';
    src: url('./assets/fonts/MyriadWebPro-Bold.ttf') format('truetype');
    font-weight: normal;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

span,
p,
div,
button,
code,
li,
ul {
    font-family: 'Poppins-Regular' !important;
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

.container {
    padding: 40px;
    background: #419be0;
    height: 500px;
    width: 800px;
}

.slick-list {
    overflow: hidden;
}

.slick-track {
    display: flex;
}

.slick-dots {
    display: none !important;
}

.rcp {
    max-width: calc(100vw - 34px);
}

#scroll-bar::-webkit-scrollbar {
    width: 8px;
}

body::-webkit-scrollbar {
    width: 8px;
}
iFrame::-webkit-scrollbar {
    width: 8px;
}
.source_code::-webkit-scrollbar {
    width: 8px !important;
}

#video-content::-webkit-scrollbar {
    width: 0px !important;
}

body {
    scrollbar-width: thin;
    scrollbar-color: #90a4ae #cfd8dc;
}
body::-webkit-scrollbar-track {
    background: #e6ebf1;
}
body::-webkit-scrollbar-thumb {
    background-color: #d2d2d2;
    border-radius: 6px;
    border: 1px solid #cfd8dc;
}

.ticker {
    width: 100% !important;
}
